import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getBanners(params) {
  return request({
    url: ENDPOINT.BANNERS,
    method: 'get',
    params
  })
}

export function getBanner(id, params = {}) {
  return request({
    url: `${ENDPOINT.BANNERS}/${id}`,
    method: 'get',
    params
  })
}

export function createBanner(data) {
  return request({
    url: ENDPOINT.BANNERS,
    method: 'post',
    data
  })
}

export function deleteBanner(id) {
  return request({
    url: `${ENDPOINT.BANNERS}?id=${id}`,
    method: 'delete'
  })
}

export function updateBanner(id, data) {
  return request({
    url: `${ENDPOINT.BANNERS}?id=${id}`,
    method: 'patch',
    data
  })
}
